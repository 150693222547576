import sortedUniq from "lodash/sortedUniq"

function getSongRefTitle({
  title,
  originalTitle,
  pubyear,
  lyricists,
  songwriters,
}) {
  const strBuf = [title]
  const attrs = [originalTitle && `«${originalTitle}»`, pubyear].filter(Boolean)

  if (attrs.length !== 0) {
    strBuf.push(`(${attrs.join(", ")})`)
  }

  const artists = sortedUniq(
    [...songwriters, ...lyricists]
      .map(artist => artist.name)
      .sort((a, b) => a.localeCompare(b))
  )

  if (artists.length !== 0) {
    strBuf.push("-", artists.join(", "))
  }

  return strBuf.join(" ")
}

export default getSongRefTitle
